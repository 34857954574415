<template>
  <div class="404 mt-5">
    <div class="row col justify-content-center">
      <img width="30%" src="@/assets/pictures/logo_bw.png" style="opacity: 0.5;" />
    </div>
    <div class="row col justify-content-center" style="position: absolute; top: 8em;">
      <span
        class="col text-center font-weight-bold h2"
        style="font-family: 'Long Cang';"
      >Error 404 - Page not found</span>
    </div>
    <div class="row col justify-content-center my-3">
      <span
        class="col text-center font-weight-bold"
        style="font-family: 'Long Cang';"
      >Diese Webseite haben wir nicht gefunden!</span>
    </div>
    <div class="row col justify-content-center my-3">
      <span class="col text-center font-weight-bold" style="font-family: 'Long Cang';">
        <router-link class="text-secondary" to="/">Startseite</router-link>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "404"
};
</script>
